import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer";
import styled from "styled-components";

const CopyRight = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      copyRight: allPrismicPravni(filter: { lang: { eq: "cs-cz" } }) {
        nodes {
          lang
          data {
            pravni_group {
              pravni_napsah {
                raw {
                  text
                }
              }
              pravni_obash {
                raw {
                  text
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <Navbar />
      <div className={className}>
        <div className="copyRight">
          {data.copyRight.nodes[0].data.pravni_group.map((item, index) => {
            return (
              <div key={index} className="copyRight-container">
                <div className="copyRight-title">
                  <h2>{item.pravni_napsah.raw[0].text}</h2>
                </div>
                <div className="copyRight-text">
                  <p>{item.pravni_obash.raw[0].text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer>
        <div>
          <div className="footer">
            <div className="top-footer">
              <p>
                CENTRUMSPED s.r.o. < br /> Výstaviště 416, Bubeneč < br /> 170 00 Praha 7 < br /> IČ:
                47124598 < br /> DIČ: CZ47124598 < br /> (VAT-TAX No., EORI No.)
              </p>
            </div>
            <div className="bottom-footer">
              <p> Právní upozornění</p>
              <p>©2020 CENTRUMSPED s.r.o. Všechna práva vyhrazena.</p>
            </div>
          </div>
        </div>
      </Footer>
    </>
  );
};

export default styled(CopyRight)`
  max-width: 500px;
  margin: 0 auto;

  .copyRight {
    margin-bottom: 204px;
  }

  .copyRight-container {
  }

  .copyRight-title {
    margin-top: 50px;
  }

  .copyRight-title h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  .copyRight-text p {
    text-align: justify;
    padding: 20px;
    word-break: break-word;
    margin: auto 0;
  }

  @media (min-width: 1025px) {
    max-width: 1000px;
    margin: 0 auto;

    .copyRight {
      margin-bottom: 204px;
    }

    .copyRight-container {
    }

    .copyRight-title {
      margin-top: 50px;
    }

    .copyRight-title h2 {
      text-align: center;
      margin-bottom: 30px;
    }

    .copyRight-text p {
      text-align: center;
      margin: auto 0;
    }
  }
`;
